// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './contexts/userContext';
import LoadingScreen from './LoadingScreen';

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUser();
  
  console.log('[ProtectedRoute] Detailed user state:', {
    isLoading,
    userId: user?.id,
    subscriptionActive: user?.subscriptionActive,
    subscriptionPlan: user?.subscriptionPlan,
    fullUser: user
  });

  if (isLoading) {
    console.log('[ProtectedRoute] component: Still loading user state, displaying LoadingScreen');
    return <LoadingScreen />;
  }

  if (!user) {
    console.log('[ProtectedRoute] component: No user found, redirecting to login');
    return <Navigate to="/login" replace />;
  }

  // Check subscription status
  const hasValidSubscription = user.subscriptionActive === true;
  const isOnPricingPage = window.location.pathname === '/pricing';
  
  console.log('[ProtectedRoute] Subscription check:', {
    subscriptionActive: user.subscriptionActive,
    subscriptionPlan: user.subscriptionPlan,
    hasValidSubscription,
    isOnPricingPage
  });

  // If no subscription and not on pricing page, redirect to pricing
  if (!hasValidSubscription && !isOnPricingPage) {
    console.log('[ProtectedRoute] component: No active subscription, redirecting to pricing');
    return <Navigate to="/pricing" replace />;
  }

  // If has subscription and on pricing page, redirect to home
  if (hasValidSubscription && isOnPricingPage) {
    console.log('[ProtectedRoute] component: User has active subscription and is on pricing page, redirecting to home');
    return <Navigate to="/app" replace />;
  }

  console.log('[ProtectedRoute] component: User authenticated and has active plan, rendering children');
  return children;
};

export default ProtectedRoute;
