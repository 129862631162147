// src/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './contexts/userContext';
import LoadingScreen from './LoadingScreen';

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUser();
  const location = useLocation();

  console.log('[ProtectedRoute] Detailed user state:', {
    isLoading,
    userId: user?.id,
    subscriptionActive: user?.subscriptionActive,
    subscriptionPlan: user?.subscriptionPlan,
    fullUser: user
  });

  if (isLoading) {
    console.log('[ProtectedRoute] component: Still loading user state, displaying LoadingScreen');
    return <LoadingScreen />;
  }

  if (!user) {
    console.log('[ProtectedRoute] No user, redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hasValidSubscription = Boolean(
    user.subscriptionActive === true && 
    user.subscriptionPlan === 'Premium'
  );

  console.log('[ProtectedRoute] Subscription check:', {
    subscriptionActive: user.subscriptionActive,
    subscriptionPlan: user.subscriptionPlan,
    hasValidSubscription
  });

  if (!hasValidSubscription) {
    console.log('[ProtectedRoute] No valid subscription, redirecting to pricing');
    return <Navigate to="/pricing" state={{ from: location }} replace />;
  }

  console.log('[ProtectedRoute] component: User authenticated and has active plan, rendering children');
  return children;
};

export default ProtectedRoute;
