// src/App.js

import React, { useState, Suspense, lazy } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import ErrorBoundary from './ErrorBoundary';

// Import your LoadingScreen component
import LoadingScreen from './LoadingScreen';

// Web application styles
import './AvatarCreationPage.css';
import './styles/global.css';

// Import the state management providers
import { GoalProvider } from './GoalStateManager';
import { AchievementsProvider } from './AchievementsStateManager';
import { RecommendationProvider } from './contexts/RecommendationContext';

// Import components and pages
const SideMenu = lazy(() => import('./SideMenu'));
const TopNavigationBar = lazy(() => import('./TopNavigationBar'));
const HomePage = lazy(() => import('./HomePage'));
const EvaluationPage = lazy(() => import('./EvaluationPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const GoalPage = lazy(() => import('./GoalPage'));
const JournalPage = lazy(() => import('./JournalPage'));
const HumanAdvicePage = lazy(() => import('./HumanAdvicePage'));
const ChallengePage = lazy(() => import('./ChallengePage'));
const CommunityPage = lazy(() => import('./CommunityPage'));
const AccountPage = lazy(() => import('./AccountPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const EarthPage = lazy(() => import('./EarthPage'));
const WaterPage = lazy(() => import('./WaterPage'));
const AirPage = lazy(() => import('./AirPage'));
const FirePage = lazy(() => import('./FirePage'));
const SupportPage = lazy(() => import('./SupportPage'));
const SignUpPage = lazy(() => import('./SignUpPage'));
const AvatarCreationPage = lazy(() => import('./AvatarCreationPage'));
const GiftPage = lazy(() => import('./GiftPage'));
const RecommendationPage = lazy(() => import('./RecommendationPage'));
const InsightsAndRemindersPage = lazy(() => import('./InsightsAndRemindersPage'));
const AdminHomePage = lazy(() => import('./AdminHomePage'));
const LandingPageTopNavigationBar = lazy(() => import('./LandingPageTopNavigationBar'));
const LandingPageHome = lazy(() => import('./LandingPageHome'));
const LandingPageContact = lazy(() => import('./LandingPageContact'));
const LandingPageAboutUs = lazy(() => import('./LandingPageAboutUs'));
const QuickAdvicePage = lazy(() => import('./QuickAdvicePage'));
const UnauthorizedPage = lazy(() => import('./UnauthorizedPage'));
const PricingPage = lazy(() => import('./PricingPage'));
import PaymentSuccessPage from './PaymentSuccessPage';

// Import the ProtectedRoute component
import ProtectedRoute from './ProtectedRoute';

function App() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const location = useLocation();

  const handleSideMenuToggle = () => {
    setSideMenuOpen(!sideMenuOpen);
    console.log('Sidebar state changed:', !sideMenuOpen);
  };

  // Determine if the side menu should be displayed
  const shouldShowSideMenu = location.pathname.startsWith('/app');
  const isLandingPage = !location.pathname.startsWith('/app') && location.pathname !== '/pricing';

  // Define the render layout based on page type
  const renderLayout = () => {
    return (
      <>
        {shouldShowSideMenu && (
          <SideMenu
            open={sideMenuOpen}
            onMenuClose={handleSideMenuToggle}
            setMobileOpen={setSideMenuOpen}
          />
        )}
        {isLandingPage ? (
          <LandingPageTopNavigationBar />
        ) : (
          <TopNavigationBar setMobileOpen={setSideMenuOpen} />
        )}
        <div
          className={`App-container ${sideMenuOpen && shouldShowSideMenu ? 'sidebar-open' : ''}`}
        >
          <main
            className={`App-content ${isLandingPage ? 'landing-content' : 'main-content'} ${
              sideMenuOpen && shouldShowSideMenu ? 'sidebar-open' : ''
            }`}
          >
            <Routes>
              {/* Protected Routes */}
              <Route path="/app" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
              <Route path="/app/evaluation" element={<ProtectedRoute><EvaluationPage /></ProtectedRoute>} />
              <Route path="/app/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
              <Route path="/app/goals" element={<ProtectedRoute><GoalPage /></ProtectedRoute>} />
              <Route path="/app/journal" element={<ProtectedRoute><JournalPage /></ProtectedRoute>} />
              <Route path="/app/advice" element={<ProtectedRoute><HumanAdvicePage /></ProtectedRoute>} />
              <Route path="/app/challenges" element={<ProtectedRoute><ChallengePage /></ProtectedRoute>} />
              <Route path="/app/community" element={<ProtectedRoute><CommunityPage /></ProtectedRoute>} />
              <Route path="/app/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
              <Route path="/app/earth" element={<ProtectedRoute><EarthPage /></ProtectedRoute>} />
              <Route path="/app/water" element={<ProtectedRoute><WaterPage /></ProtectedRoute>} />
              <Route path="/app/air" element={<ProtectedRoute><AirPage /></ProtectedRoute>} />
              <Route path="/app/fire" element={<ProtectedRoute><FirePage /></ProtectedRoute>} />
              <Route path="/app/support" element={<ProtectedRoute><SupportPage /></ProtectedRoute>} />
              <Route path="/app/avatar" element={<ProtectedRoute><AvatarCreationPage /></ProtectedRoute>} />
              <Route path="/app/recommendations" element={<ProtectedRoute><RecommendationPage /></ProtectedRoute>} />
              <Route path="/app/insightsandreminders" element={<ProtectedRoute><InsightsAndRemindersPage /></ProtectedRoute>} />
              <Route path="/app/quickadvice" element={<ProtectedRoute><QuickAdvicePage /></ProtectedRoute>} />
              <Route path="/app/gift" element={<ProtectedRoute><GiftPage /></ProtectedRoute>} />
              <Route path="/app/admin" element={<ProtectedRoute><AdminHomePage /></ProtectedRoute>} />

              {/* Fallback Route */}
              <Route path="*" element={<Navigate to="/unauthorized" replace />} />
            </Routes>
          </main>
        </div>
      </>
    );
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <LandingPageTopNavigationBar />
                <LandingPageHome />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <LandingPageTopNavigationBar />
                <LandingPageAboutUs />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <LandingPageTopNavigationBar />
                <LandingPageContact />
              </>
            }
          />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/login"
            element={
              <UserProvider>
                <>
                  <LandingPageTopNavigationBar />
                  <LoginPage />
                </>
              </UserProvider>
            }
          />
          <Route
            path="/signup"
            element={
              <UserProvider>
                <>
                  <LandingPageTopNavigationBar />
                  <SignUpPage />
                </>
              </UserProvider>
            }
          />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route
            path="/payment-success"
            element={
              <UserProvider>
                <PaymentSuccessPage />
              </UserProvider>
            }
          />

          {/* Protected Routes */}
          <Route
            path="/*"
            element={
              <UserProvider>
                <RecommendationProvider>
                  <GoalProvider>
                    <AchievementsProvider>
                      {renderLayout()}
                    </AchievementsProvider>
                  </GoalProvider>
                </RecommendationProvider>
              </UserProvider>
            }
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;



/* Commented-Out Routes for Future Use or Reference */
// <Route path="/app/reminders" element={<RemindersPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/app/insights" element={<InsightsPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/app/achievements" element={<AchievementsPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/app/progress" element={<ProgressPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/app/settings" element={<SettingsPage />} /> // Not in use for the first version
