// serviceWorkerRegistration.js

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    // IPv4 localhost address
    window.location.hostname.match(/^127(?:\.(?:\d{1,3})){3}$/)
);

function registerValidSW(swUrl, config) {
  let engagementTimer;
  let pageViews = parseInt(localStorage.getItem('pwa_page_views') || '0', 10);
  let timeSpent = parseInt(localStorage.getItem('pwa_time_spent') || '0', 10);
  let lastUpdateTime = Date.now();

  console.log('🚀 Starting PWA tracking:', {
    pageViews,
    timeSpent,
    timestamp: new Date().toISOString(),
    environment: process.env.NODE_ENV,
  });

// Handle installation prompts globally
const handleInstallPrompt = (e) => {
  console.log('📱 Install prompt detected:', {
    platforms: e.platforms,
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
  });

  e.preventDefault();
  window.deferredPrompt = e;
  window.dispatchEvent(new CustomEvent('installable', { detail: e }));

  console.log('💾 Install prompt stored globally');
};

// Add the handler before registration
window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
window.addEventListener('beforeinstallprompt', handleInstallPrompt);

// Check if the app is already installed (standalone mode or iOS)
const isAppInstalled =
  window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

console.log(`🔍 Is the app installed? ${isAppInstalled}`);
if (isAppInstalled) {
  localStorage.setItem('isInstalled', 'true');
  console.log('App installation status saved in localStorage.');
}

  return navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      console.log('✅ Service Worker registered:', {
        scope: registration.scope,
        state: registration.active?.state || 'no-active-worker',
        environment: process.env.NODE_ENV,
      });

      // Handle updates
      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        console.log('🔄 New service worker installing:', {
          state: installingWorker?.state,
          timestamp: new Date().toISOString(),
        });

        installingWorker?.addEventListener('statechange', () => {
          console.log('👷 Service Worker state changed:', installingWorker.state);
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New update available
              console.log('✨ New content is available; please refresh.');
              // Optionally, dispatch an event or show a notification to the user
            } else {
              // Content cached for offline use
              console.log('🔌 Content is cached for offline use.');
            }
          } else if (installingWorker.state === 'activated') {
            console.log('🚀 New Service Worker activated');
            window.location.reload();
          }
        });
      });

      // Increment page views immediately
      pageViews++;
      localStorage.setItem('pwa_page_views', pageViews.toString());

      // Track engagement
      engagementTimer = setInterval(() => {
        const currentTime = Date.now();
        const deltaTime = currentTime - lastUpdateTime;
        timeSpent += deltaTime;
        lastUpdateTime = currentTime;

        localStorage.setItem('pwa_time_spent', timeSpent.toString());

        const meetsCriteria = pageViews >= 2 && timeSpent >= 30000;

        console.log('📊 Engagement tracking:', {
          pageViews,
          timeSpent,
          meetsCriteria,
        });

        if (registration.active && meetsCriteria) {
          console.log('🔄 Updating service worker due to engagement criteria met.');
          registration.update();
        }
      }, 2000);

      // Take control immediately
      if (registration.active) {
        console.log('🎮 Taking immediate control');
        registration.active.postMessage({ type: 'TAKE_CONTROL' });

        if (!navigator.serviceWorker.controller) {
          console.log('🚀 No existing controller, skipping waiting.');
          registration.active.postMessage({ type: 'SKIP_WAITING' });
        }
      }

      // Cleanup on unload
      window.addEventListener('beforeunload', () => {
        if (engagementTimer) {
          clearInterval(engagementTimer);
          const finalTime = Date.now() - lastUpdateTime;
          timeSpent += finalTime;
          localStorage.setItem('pwa_time_spent', timeSpent.toString());
          console.log('💾 Final engagement data saved:', {
            pageViews,
            timeSpent,
          });
        }
      });

      return registration;
    })
    .catch((error) => {
      console.error('❌ Service Worker registration failed:', error);
      throw error;
    });
}

function checkValidServiceWorker(swUrl, config) {
  console.log('🔍 Checking for valid service worker at:', swUrl);
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        console.warn('⚠️ No service worker found. Reloading the page.');
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            console.log('🔄 Unregistered old service worker.');
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        console.log('✅ Valid service worker found. Registering...');
        registerValidSW(swUrl, config);
      }
    })
    .catch((error) => {
      console.log(
        '🛑 No internet connection found. App is running in offline mode.',
        error
      );
    });
}

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    console.log('🌐 Service Worker registration starting (production mode)');
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      console.warn(
        '⚠️ Service Worker not registered due to different origins:',
        {
          publicUrlOrigin: publicUrl.origin,
          windowLocationOrigin: window.location.origin,
        }
      );
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      console.log('📝 Service Worker URL:', swUrl);

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        console.log('🏠 Localhost detected. Checking for valid service worker.');
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            '✅ This web app is being served cache-first by a service worker.'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        console.log('🌐 Non-localhost environment. Registering service worker.');
        registerValidSW(swUrl, config);
      }

      // Check for existing controller
      if (navigator.serviceWorker.controller) {
        console.log('🧭 Existing service worker controller detected:', {
          state: navigator.serviceWorker.controller.state,
        });
      } else {
        console.log('🚫 No existing service worker controller.');
      }

      // Setup periodic update checks
      setInterval(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then((registration) => {
            console.log('🔄 Periodic service worker update check.');
            registration.update();
          });
        }
      }, 60 * 60 * 1000); // Check every hour
    });
  } else {
    console.log('🛠️ Service Worker not registered (development mode or unsupported).', {
      environment: process.env.NODE_ENV,
      serviceWorkerSupported: 'serviceWorker' in navigator,
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister().then(() => {
          console.log('🗑️ Service Worker unregistered successfully.');
        });
      })
      .catch((error) => {
        console.error('❌ Error during service worker unregistration:', error);
      });
  } else {
    console.log('🚫 Service Worker not supported in this browser.');
  }
}
