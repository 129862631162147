//src/PaymentSuccessPage.js

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import authApiClient from './authApiClient';
import { useUser } from './contexts/userContext';

const PaymentSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const sessionId = searchParams.get('session_id');
  const { user, isLoading: isUserLoading, setUser } = useUser();
  const isMounted = useRef(true);

  useEffect(() => {
    // Set up cleanup
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const verifyPayment = async () => {
      if (!sessionId) {
        console.error('[PaymentSuccess] No session ID found');
        if (isMounted.current) navigate('/pricing');
        return;
      }

      const storedUserId = localStorage.getItem('signup_user_id');
      console.log('[PaymentSuccess] Stored user ID:', storedUserId);

      try {
        const verifyResponse = await authApiClient.get(`/stripe_pricing/verify-payment`, {
          params: {
            session_id: sessionId,
            user_id: storedUserId
          }
        });
        
        if (verifyResponse.data.success) {
          if (isMounted.current) {
            // Update user context first
            await setUser({
              ...user,
              subscriptionActive: true,
              subscriptionPlan: 'Premium'
            });

            // Clean up storage
            localStorage.removeItem('signup_user_id');
            
            // Update URL without triggering navigation
            window.history.replaceState({}, document.title, '/app');
            
            // Set loading false before navigation
            setLoading(false);
            
            // Navigate last
            navigate('/app', { replace: true });
          }
        } else {
          throw new Error(verifyResponse.data.error || 'Payment verification failed');
        }
      } catch (error) {
        if (isMounted.current) {
          console.error('[PaymentSuccess] Error details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
          });
          setLoading(false);
          setError(
            error.response?.data?.error || 
            error.message || 
            'There was an error verifying your payment. Please contact support.'
          );
        }
      }
    };

    if (!isUserLoading) {
      verifyPayment();
    }
  }, [sessionId, navigate, isUserLoading, user, setUser]);

  // Auth check
  useEffect(() => {
    if (!isUserLoading && !user) {
      navigate('/login');
    }
  }, [user, isUserLoading, navigate]);

  if (loading || isUserLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-6 bg-white rounded-xl shadow-lg">
          <div className="text-center">
            <h2 className="text-xl font-bold text-gray-900 mb-2">Payment Verification Error</h2>
            <p className="text-red-500">{error}</p>
            <button 
              onClick={() => navigate('/pricing')}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Return to Pricing
            </button>
          </div>
        </div>
      </div>
    );
  }

  return <LoadingScreen />;
};

export default PaymentSuccessPage; 