import React, { Suspense } from 'react';
import ReactDOM from 'react-dom'; 
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import './index.css';
import LoadingScreen from './LoadingScreen';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Create your MUI theme
const theme = createTheme({
  // ...
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingScreen />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Register service worker in production
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log('🚀 Service Worker registered successfully:', {
        scope: registration.scope,
        timestamp: new Date().toISOString(),
      });
    },
    onUpdate: (registration) => {
      console.log('🔄 Service Worker update available:', {
        state: registration.installing?.state,
        timestamp: new Date().toISOString(),
      });
      // Optionally, prompt the user to refresh
    },
  });
} else {
  console.log('🛠️ Service Worker registration skipped (development mode)');
  // Unregister any existing service workers in dev
  serviceWorkerRegistration.unregister();
}
