import React, { createContext, useContext, useState, useCallback } from 'react';
import authApiClient from '../authApiClient';

const RecommendationContext = createContext();

export const useRecommendation = () => useContext(RecommendationContext);

export const RecommendationProvider = ({ children }) => {
    const [lastSearch, setLastSearch] = useState('');
    const [carouselCategories, setCarouselCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userSearches, setUserSearches] = useState([]);
    const [allSearchesCategories, setAllSearchesCategories] = useState([]); // Add this state
    const [saving, setSaving] = useState(false);

    const fetchCarouselCategories = useCallback(async () => {
        if (!lastSearch || !lastSearch.trim()) {
            console.log('No valid search term provided.');
            return;
        }

        setIsLoading(true);
        setError(null);

        const isAllSearches = lastSearch === 'All Searches';
        const encodedInput = encodeURIComponent(lastSearch.trim().toLowerCase());
        const url = isAllSearches
            ? '/recommendations_all_searches'
            : `/recommendations/carousel-categories?userInput=${encodedInput}`;

        console.log(`Fetching categories from URL: ${url}`);

        try {
            const response = await authApiClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
            });

            const data = response.data;
            console.log('Fetched data:', data);

            let categories = isAllSearches ? data.allSearchesCategories : data.carouselCategories || [];

            // Update the carousel categories state
            setCarouselCategories(categories);

        } catch (error) {
            console.error('HTTP error!', error);
            setError('Failed to fetch categories');
            setCarouselCategories([]);
        } finally {
            setIsLoading(false);
        }
    }, [lastSearch]);

    const refreshCategories = useCallback(() => {
        fetchCarouselCategories();
    }, [fetchCarouselCategories]);

    const refreshUserSearches = useCallback(async () => {
        try {
            const response = await authApiClient.get('/recommendations/past-searches');
            setUserSearches(response.data.searchHistory || []);
            console.log('[RecommendationContext] User searches refreshed:', response.data.searchHistory);
        } catch (error) {
            console.error('[RecommendationContext] Error fetching past searches:', error);
        }
    }, []);

    return (
        <RecommendationContext.Provider
            value={{
                lastSearch,
                setLastSearch,
                carouselCategories,
                setCarouselCategories,
                isLoading,
                setIsLoading,
                error,
                setError,
                userSearches,
                setUserSearches,
                allSearchesCategories, // Added to context
                setAllSearchesCategories, // Added to context
                refreshCategories,
                fetchCarouselCategories,
                refreshUserSearches,
                saving,
                setSaving,
            }}
        >
            {children}
        </RecommendationContext.Provider>
    );
};
